import React from 'react';
import { useConfiguration } from '../../context/configurationContext';
import loadable from '@loadable/component';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ensureCurrentUser } from '../../util/data';
import { isUserTypeProvider } from '../../util/userHelpers';

const SectionBuilder = loadable(
  () => import(/* webpackChunkName: "SectionBuilder" */ '../PageBuilder/PageBuilder'),
  {
    resolveComponent: components => components.SectionBuilder,
  }
);

const FooterComponent = props => {
  const { footer = {}, topbar } = useConfiguration();

  const { currentUser, isAuthenticated } = props;

  const isProvider = isUserTypeProvider(currentUser);

  // If footer asset is not set, let's not render Footer at all.
  if (Object.keys(footer).length === 0) {
    return null;
  }

  let { blocks } = footer;
  const filteredBlocks = blocks.map(b => {
    if (b.blockId === 'general' && !isProvider) {
      return {
        ...b,
        text: {
          content: '- [About us](/p/about)' + '\n - [Search services](/s)',
          fieldType: 'markdown',
        },
      };
    } else {
      return b;
    }
  });

  footer['blocks'] = isProvider || !isAuthenticated ? footer['blocks'] : filteredBlocks;

  // The footer asset does not specify sectionId or sectionType. However, the SectionBuilder
  // expects sectionId and sectionType in order to identify the section. We add those
  // attributes here before passing the asset to SectionBuilder.
  const footerSection = {
    ...footer,
    sectionId: 'footer',
    sectionType: 'footer',
    linkLogoToExternalSite: topbar?.logoLink,
  };

  return <SectionBuilder currentUser={currentUser} sections={[footerSection]} />;
};

// NOTE: if you want to add dynamic data to FooterComponent,
//       you could just connect this FooterContainer to Redux Store
//
const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const { currentUser } = state.user;
  return { currentUser, isAuthenticated };
};
const FooterContainer = compose(connect(mapStateToProps))(FooterComponent);
export default FooterContainer;

// export default FooterComponent;
