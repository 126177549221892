import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';
import { resolveLatestProcessName, getProcess } from '../../transactions/transaction';

import css from './OrderBreakdown.module.css';
import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;

const LineItemTotalPrice = props => {
  const { transaction, isProvider, intl, commissionLineItem } = props;
  const processName = resolveLatestProcessName(transaction?.attributes?.processName);
  if (!processName) {
    return null;
  }
  const process = getProcess(processName);
  const isCompleted = process.isCompleted(transaction?.attributes?.lastTransition);
  const isRefunded = process.isRefunded(transaction?.attributes?.lastTransition);
  const isPartialRefund = !!transaction?.attributes?.metadata?.partialRefundData || false;
  let payoutTotalWithRefund, payinTotalWithRefund;

  if(isPartialRefund) {
    const partialRefundAmount = transaction.attributes.metadata.partialRefundData.amount;
    const payinTotal = transaction.attributes.payinTotal.amount;
    const commissionTotal = isProvider ? -commissionLineItem[0].lineTotal.amount : commissionLineItem[0].lineTotal.amount;

    payoutTotalWithRefund = new Money((payinTotal - partialRefundAmount), 'CAD');
    payinTotalWithRefund = new Money((payinTotal - partialRefundAmount), 'CAD');
  }

  let providerTotalMessageId = 'OrderBreakdown.providerTotalDefault';
  if (isCompleted) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalReceived';
  } else if (isRefunded) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalRefunded';
  }

  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : (
    <FormattedMessage id="OrderBreakdown.total" />
  );

  let totalPrice;

  if(isProvider) {
    if(isPartialRefund) {
      totalPrice = payoutTotalWithRefund
    } else {
      totalPrice = transaction.attributes.payoutTotal
    }
  } else {
    if(isPartialRefund) {
      totalPrice = payinTotalWithRefund
    } else {
      totalPrice = transaction.attributes.payinTotal
    }
  }

  const formattedTotalPrice = formatMoney(intl, totalPrice);

  return (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{totalLabel}</div>
        <div className={css.totalPrice}>{formattedTotalPrice}</div>
      </div>
    </>
  );
};

LineItemTotalPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTotalPrice;
