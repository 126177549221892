import React, { useState, useEffect, useMemo } from 'react';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import AdvancedSearchForm from './AdvancedSearchForm';
import classNames from 'classnames';
import { fetchZoomedBounds } from '../../util/maps';
import { parse } from '../../util/urlHelpers';
import { expandBoundingBox } from '../../util/search';
import css from './AdvancedSearch.module.css';
import { stringifyDateToISO8601 } from '../../util/dates';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import { types as sdkTypes } from '../../util/sdkLoader';
import { useHistory, useLocation } from 'react-router-dom';

const { LatLng, LatLngBounds } = sdkTypes;



const AdvancedSearch = props => {
  const { intl, topbarLayout, categories } = props;
  const routeConfiguration = useRouteConfiguration();
  const history = useHistory();
  const location = useLocation();

  const currentSearchParams = useMemo(() => parse(location.search), [location.search]);


  const [datesFilter, setDatesFilter] = useState({});
  const [datesFilterOpen, setDatesFilterOpen] = useState(false);
  const [typeFilterOpen, setTypeFilterOpen] = useState(false);

  const updatesDatesFilter = dates => {
    setDatesFilter(dates);
  };

  const onSubmit = () => {
    return;
  };

  const getValues = values => {
    const { history, routeConfiguration } = props;
    const { dates, category, location } = values;
    const selectedPlace = location?.selectedPlace || {};


    // dates params
    const hasDates = !!dates;
    const { startDate, endDate, date } = hasDates ? dates : {};
    const start = date ? stringifyDateToISO8601(date) : null;
    const end = date ? stringifyDateToISO8601(date) : null;
    const dateValue = start && end ? `${start},${end}` : null;


    // location params
    const bounds = values?.location?.selectedPlace?.bounds;
    const boundsWithRadius = selectedPlace.bounds
      ? expandBoundingBox(
        selectedPlace.address === currentSearchParams.address ? currentSearchParams.placeBounds : selectedPlace.bounds,
        currentSearchParams.pub_radius
      ) : null;


    const searchParams = {
      address: !!location?.selectedPlace?.address
        ? location?.selectedPlace?.address
        : null,
      bounds: boundsWithRadius,
      placeBounds: selectedPlace.bounds || null,
      origin: !!location?.selectedPlace?.origin
        ? location?.selectedPlace?.origin
        : null,
      pub_radius: +currentSearchParams.pub_radius,
      pub_categoryLevel1: !!category ? category : null,
      dates: !!dates ? dateValue : null,
    };


    !!history && history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
  };

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true)
  }, [])

  if (!isMounted) return null

  return (
    <div className={classNames(css.root, { [css.rootTopbar]: !!topbarLayout })}>
      <h1 className={css.title}>
        <FormattedMessage id="AdvancedSearch.title" />
      </h1>
      <h2 className={css.subTitle}>
        <FormattedMessage id="AdvancedSearch.subTitle" />
      </h2>
      <AdvancedSearchForm
        topbarLayout={topbarLayout}
        onSubmit={onSubmit}
        getValues={getValues}
        intl={intl}
        setDatesFilterOpen={setDatesFilterOpen}
        datesFilterOpen={datesFilterOpen}
        setTypeFilterOpen={setTypeFilterOpen}
        typeFilterOpen={typeFilterOpen}
        updatesDatesFilter={updatesDatesFilter}
        datesFilter={datesFilter}
        categories={categories}
      />
    </div>
  );
};

AdvancedSearch.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(AdvancedSearch);
