import React from 'react';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  LISTING_BOAT,
  LISTING_JET_SKI,
  LISTING_FISHING,
  LISTING_WATERSPORT
} from '../../util/types';
import {
  autocompletePlaceSelected,
  composeValidators,
} from '../../util/validators';
import {
  Form,
  FieldLocationAutocompleteInput,
  Button,
  FieldSelect,
  IconSearch,
  OutsideClickHandler, FieldDateRangeController, FieldSingleDatePicker
} from '../../components';
import BookingDateRangeFilter from '../../containers/SearchPage/BookingDateRangeFilter/BookingDateRangeFilter';

import css from './AdvancedSearch.module.css';
import { stringifyDateToISO8601 } from '../../util/dates';

const identity = v => v;

export const AdvancedSearchForm = props => (
  <FinalForm
    mutators={{ ...arrayMutators }}
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        intl,
        invalid,
        pristine,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        onSubmit,
        getValues,
        datesFilterOpen,
        setDatesFilterOpen,
        categories,
        datesFilter,
      } = formRenderProps;

      const { dates } = values;
      const hasDates = !!dates;
      const startDate = hasDates ? dates.date : null;
      const endDate = hasDates ? dates.date : null;

      const format = {
        month: 'short',
        day: 'numeric',
      };

      const formattedStartDate = hasDates ? intl.formatDate(startDate, format) : null;
      const formattedEndDate = hasDates ? intl.formatDate(endDate, format) : null;

      const labelForPopup = hasDates
        ? intl.formatMessage(
          { id: 'BookingDateRangeFilter.labelSelectedPopup' },
          {
            dates: `${formattedStartDate} - ${formattedEndDate}`,
          }
        ) : intl.formatMessage({ id: 'AdvancedSearch.datesPlaceholder' });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.showListingFailed" />
        </p>
      ) : null;

      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const datesFilterSelected = !!datesFilter?.dates;

      return (
        <Form
          className={css.rootForm}
          onSubmit={e => {
            e.preventDefault(), onSubmit;
          }}
        >
          {errorMessage}
          {errorMessageShowListing}

          <div className={css.locationHolder}>
            <FieldLocationAutocompleteInput
              inputClassName={css.locationAutocompleteInput}
              iconClassName={css.locationAutocompleteInputIcon}
              autoFocus={false}
              name="location"
              placeholder={intl.formatMessage({
                id: 'AdvancedSearch.locationPlaceholder',
              })}
              useDefaultPredictions={false}
              format={identity}
              valueFromForm={values.location}
              // validate={composeValidators(autocompletePlaceSelected(addressNotRecognizedMessage))}
            />
          </div>

          <div className={css.selectCategoryHolder}>
            <FieldSelect selectClassName={css.selectCategory} id="category" name="category">
              <option value="">
                {intl.formatMessage({
                  id: 'AdvancedSearch.categoryPlaceholder',
                })}
              </option>
              {categories.map((category, index) => {
                return <option key={index} value={category.id}>{category.name}</option>;
              })}
            </FieldSelect>
          </div>

          <div className={css.datesHolder}>
            <FieldSingleDatePicker
              className={css.fieldDatePicker}
              inputClassName={css.fieldDateInput}
              popupClassName={css.fieldDatePopup}
              placeHolderClassName={css.placeHolder}
              placeholderText={intl.formatMessage({
                id: 'AdvancedSearch.datesPlaceholder',
              })}
              name="dates"
              controllerRef={node => {
                this.popupControllerRef = node;
              }}
            />
          </div>


          {/*<OutsideClickHandler*/}
          {/*  onOutsideClick={() => setDatesFilterOpen(false)}*/}
          {/*  className={css.datesHolder}*/}
          {/*>*/}
          {/*  <div*/}
          {/*    className={classNames(*/}
          {/*      { [css.open]: datesFilterOpen },*/}
          {/*      { [css.closed]: !datesFilterOpen }*/}
          {/*    )}*/}
          {/*  >*/}
          {/*    <div*/}
          {/*      onClick={() => setDatesFilterOpen(!datesFilterOpen)}*/}
          {/*      className={classNames(css.menuLabel, {*/}
          {/*        [css.menuLabelActive]: datesFilterSelected,*/}
          {/*      })}*/}
          {/*    >*/}
          {/*      {labelForPopup}*/}
          {/*    </div>*/}

          {/*    <div className={css.categoryMenuContent}>*/}
          {/*      <FieldDateRangeController*/}
          {/*        name="dates"*/}
          {/*        controllerRef={node => {*/}
          {/*          this.popupControllerRef = node;*/}
          {/*        }}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</OutsideClickHandler>*/}

          <Button
            className={css.submitButton}
            type="button"
            inProgress={submitInProgress}
            ready={submitReady}
            onClick={() => getValues(values)}
          >
            <FormattedMessage id="AdvancedSearch.search" />
          </Button>
        </Form>
      );
    }}
  />
);

export default compose(injectIntl)(AdvancedSearchForm);
